import React, { useState } from 'react';
import { FaCopy } from 'react-icons/fa';
import '../RefLinks800px.css';

const RefLinks = () => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [showNotification, setShowNotification] = useState(false);

  const exchanges = [
    { name: 'Phemex', referralLink: 'https://phemex.com/account/referral/invite-friends-entry?referralCode=F47TJ5' },
    { name: 'MEXC', referralLink: 'https://www.mexc.com/register?inviteCode=1zPSa' },
    { name: 'Bybit', referralLink: 'https://www.bybit.com/invite?ref=XX0E9' },
  ];

  const cryptocurrencies = [
    {
      name: 'BTC',
      addresses: [
        { type: 'Bitcoin', walletAddress: '1CwiUgH9MoTPJWcUdE4E6jfrPfCaD1LsXy' },
        { type: 'BSC', walletAddress: '0xa5037fb4cd37d285087cfd8b9fb8f1aa639f3195' },
      ],
    },
    {
      name: 'ETH',
      addresses: [
        { type: 'ERC20', walletAddress: '0xa5037fb4cd37d285087cfd8b9fb8f1aa639f3195' },
        { type: 'BSC', walletAddress: '0xbb3bc4ba3b8958d6f794239a010baa7a1b3cf386' },
      ],
    },
    {
      name: 'USDT',
      addresses: [
        { type: 'ERC20', walletAddress: '0xa5037fb4cd37d285087cfd8b9fb8f1aa639f3195' },
        { type: 'BSC', walletAddress: '0xbb3bc4ba3b8958d6f794239a010baa7a1b3cf386' },
        { type: 'SOL', walletAddress: 'B6oyV54DN9eQ1WQQEHRT3w4HFc4Mh6BFjkQRMsAujf7W'},
      ],
    },
    {
      name: 'USDC',
      addresses: [
        { type: 'ERC20', walletAddress: '0xa5037fb4cd37d285087cfd8b9fb8f1aa639f3195' },
        { type: 'BSC', walletAddress: '0xbb3bc4ba3b8958d6f794239a010baa7a1b3cf386' },
        { type: 'SOL', walletAddress: 'B6oyV54DN9eQ1WQQEHRT3w4HFc4Mh6BFjkQRMsAujf7W'},
      ],
    },
  ];

  const handleItemClick = (item) => {
    setSelectedItem((prevSelectedItem) => (prevSelectedItem?.name === item.name ? null : item));
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    setShowNotification(true);
    setTimeout(() => {
      setShowNotification(false);
    }, 3000);
  };

  return (
    <div className="ref-links-container">
      <div className="ref-links-section">
        <h3>Ref Links</h3>
        <ul>
          {exchanges.map((exchange, index) => (
            <li
              key={index}
              onClick={() => handleItemClick(exchange)}
              className={selectedItem?.name === exchange.name ? 'selected' : ''}
            >
              {exchange.name}
              {selectedItem?.name === exchange.name && (
                <div className="address-container">
                  <a href={exchange.referralLink} target="_blank" rel="noopener noreferrer" className="animated-text">
                    {exchange.displayText || exchange.referralLink}
                  </a>
                  <button className="copy-button" onClick={() => copyToClipboard(exchange.referralLink)}>
                    <FaCopy />
                  </button>
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>
      <div className="ref-links-section">
        <h3>Cryptocurrencies</h3>
        <ul>
          {cryptocurrencies.map((crypto, index) => (
            <li
              key={index}
              onClick={() => handleItemClick(crypto)}
              className={selectedItem?.name === crypto.name ? 'selected' : ''}
            >
              {crypto.name}
              {selectedItem?.name === crypto.name && (
                <div className="address-container" style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                  {crypto.addresses.map((address, addressIndex) => (
                    <div key={addressIndex} style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                      <span className="animated-text">
                        <strong>{address.type}:</strong> {address.walletAddress}
                      </span>
                      <button className="copy-button" onClick={() => copyToClipboard(address.walletAddress)}>
                        <FaCopy />
                      </button>
                    </div>
                  ))}
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>
      {showNotification && (
        <div className="notification-popup">
          <p>address copied to clipboard!</p>
        </div>
      )}
    </div>
  );
};

export default RefLinks;