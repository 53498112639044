// Position.js
import React from 'react';
import '../styles.css';

const formatAmount = (amount) => {
  return Math.abs(amount).toLocaleString(undefined, { maximumFractionDigits: 2 });
};

const Position = ({ ticker, amount, exchange, assetClass }) => {
  const positionClass = amount >= 0 ? 'long' : 'short';
  const formattedAmount = formatAmount(amount);

  return (
    <div className={`position ${positionClass}`}>
      <p>{ticker} {formattedAmount} {positionClass} {exchange}</p>
    </div>
  );
};

export default Position;