import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { useTable, useSortBy } from 'react-table';
import '../SiflowStyle.css';

const formatNumber = (num) => {
  return num.toLocaleString(undefined, { maximumFractionDigits: 0 });
};

const SiFlow = () => {
  const [optionsData, setOptionsData] = useState([]);
  const [displayedData, setDisplayedData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [displayCount, setDisplayCount] = useState(20);

  useEffect(() => {
    const fetchOptionsData = async () => {
      try {
        const response = await axios.get('https://www.deribit.com/api/v2/public/get_book_summary_by_currency', {
          params: {
            currency: 'BTC',
            kind: 'option'
          }
        });

        const processedData = response.data.result.map(item => {
          const [ticker, expiry, strike, callPut] = item.instrument_name.split('-');
          const premium = (item.last_price || item.mark_price || 0) * (item.volume || 0);
          const btcPrice = item.underlying_price || 0;
          const daysToExpiration = Math.ceil((new Date(expiry) - new Date()) / (1000 * 60 * 60 * 24));
          return {
            ...item,
            time: new Date().toLocaleTimeString(),
            ticker,
            expiry,
            strike: parseFloat(strike),
            callPut,
            spot: btcPrice,
            size: item.volume,
            price: item.last_price || item.mark_price,
            premium,
            premiumUSD: premium * btcPrice,
            vol: item.bid_iv,
            daysToExpiration,
            bidPrice: item.best_bid_price,
            askPrice: item.best_ask_price,
            bidAskSpread: item.best_ask_price - item.best_bid_price,
            delta: item.greeks?.delta,
            gamma: item.greeks?.gamma,
            theta: item.greeks?.theta,
            vega: item.greeks?.vega,
            moneyness: ((btcPrice / parseFloat(strike)) - 1) * (callPut === 'C' ? 1 : -1),
          };
        });

        setOptionsData(processedData);
        setDisplayedData(processedData.slice(0, displayCount));
        setLoading(false);
      } catch (err) {
        setError('Error fetching options data');
        setLoading(false);
      }
    };

    fetchOptionsData();
    const intervalId = setInterval(fetchOptionsData, 60000);
    return () => clearInterval(intervalId);
  }, [displayCount]);

  const loadMore = () => {
    setDisplayCount(prevCount => prevCount + 20);
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Time',
        accessor: 'time',
      },
      {
        Header: 'Ticker',
        accessor: 'ticker',
      },
      {
        Header: 'Expiry',
        accessor: 'expiry',
      },
      {
        Header: 'Strike ($)',
        accessor: 'strike',
        Cell: ({ value }) => value.toFixed(0),
      },
      {
        Header: 'C/P',
        accessor: 'callPut',
      },
      {
        Header: 'Spot ($)',
        accessor: 'spot',
        Cell: ({ value }) => value.toFixed(0),
      },
      {
        Header: 'Size (Cont)',
        accessor: 'size',
        Cell: ({ value }) => formatNumber(value),
      },
      {
        Header: 'Price ($)',
        accessor: 'price',
        Cell: ({ value }) => value ? `$${value.toFixed(2)}` : '-',
      },
      // {
      //   Header: 'Bid ($)',
      //   accessor: 'bidPrice',
      //   Cell: ({ value }) => value ? `$${value.toFixed(2)}` : '-',
      // },
      // {
      //   Header: 'Ask ($)',
      //   accessor: 'askPrice',
      //   Cell: ({ value }) => value ? `$${value.toFixed(2)}` : '-',
      // },
      // {
      //   Header: 'Spread ($)',
      //   accessor: 'bidAskSpread',
      //   Cell: ({ value }) => value ? `$${value.toFixed(2)}` : '-',
      // },
      {
        Header: 'Prem (BTC)',
        accessor: 'premium',
        Cell: ({ value }) => value ? `${value.toFixed(6)} BTC` : '-',
      },
      {
        Header: 'Prem ($)',
        accessor: 'premiumUSD',
        Cell: ({ value }) => value ? `$${formatNumber(value)}` : '-',
      },
      {
        Header: 'IV (%)',
        accessor: 'vol',
        Cell: ({ value }) => (
          <span title="Implied Volatility: Market's expectation of future volatility">
            {value ? `${(value * 100).toFixed(2)}%` : '-'}
          </span>
        ),
      },
      {
        Header: 'Delta',
        accessor: 'delta',
        Cell: ({ value }) => value ? value.toFixed(4) : '-',
      },
      {
        Header: 'Gamma',
        accessor: 'gamma',
        Cell: ({ value }) => value ? value.toFixed(6) : '-',
      },
      {
        Header: 'Theta',
        accessor: 'theta',
        Cell: ({ value }) => value ? value.toFixed(4) : '-',
      },
      {
        Header: 'Vega',
        accessor: 'vega',
        Cell: ({ value }) => value ? value.toFixed(4) : '-',
      },
      {
        Header: 'Days to Exp',
        accessor: 'daysToExpiration',
      },
      {
        Header: 'Moneyness (%)',
        accessor: 'moneyness',
        Cell: ({ value }) => `${(value * 100).toFixed(2)}%`,
      },
      {
        Header: 'OI (Cont)',
        accessor: 'open_interest',
        Cell: ({ value }) => (
          <span title="Open Interest: Total number of outstanding option contracts">
            {formatNumber(value)}
          </span>
        ),
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data: displayedData }, useSortBy);

  if (loading) return <div className="loading">Loading...</div>;
  if (error) return <div className="error">{error}</div>;

  return (
    <div className="container siflow-container">
      <h1>ΣFlow</h1>
      <div className="table-container">
        <table {...getTableProps()} className="siflow-table">
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render('Header')}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? ' 🔽'
                          : ' 🔼'
                        : ''}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => (
                    <td {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {displayedData.length < optionsData.length && (
        <button onClick={loadMore} className="load-more-button">
          Load More
        </button>
      )}
    </div>
  );
};

export default SiFlow;
