// Sitrader.js
import React, { useState, useEffect } from 'react';
import Portfolio from './Portfolio';
import PositionForm from './PositionForm';
import { collection, getDocs, addDoc, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { db } from './firebase';

const Sitrader = () => {
  const [positions, setPositions] = useState([]);
  const [expandedExchanges, setExpandedExchanges] = useState([]);
  const [sortByExchange, setSortByExchange] = useState(false);

  useEffect(() => {
    const fetchPositions = async () => {
      try {
        const userUid = sessionStorage.getItem('authToken');
        const positionsRef = collection(db, `users/${userUid}/positions`);
        const positionsSnapshot = await getDocs(positionsRef);

        const loadedPositions = [];
        positionsSnapshot.forEach((doc) => {
          loadedPositions.push({
            id: doc.id,
            ...doc.data()
          });
        });

        setPositions(loadedPositions);
      } catch (error) {
        console.error('Error fetching positions:', error.message);
      }
    };

    fetchPositions();
  }, []);

  const formatNumber = (number) => {
    return number.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };

  const addPosition = async (newPosition) => {
    try {
      const userUid = sessionStorage.getItem('authToken');
      const positionsRef = collection(db, `users/${userUid}/positions`);

      await addDoc(positionsRef, {
        ...newPosition,
      });

      const positionsSnapshot = await getDocs(positionsRef);
      const updatedPositions = positionsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      setPositions(updatedPositions);
    } catch (error) {
      console.error('Error adding position:', error.message);
    }
  };

  const removePosition = async (id) => {
    try {
      const userUid = sessionStorage.getItem('authToken');
      const positionsRef = collection(db, `users/${userUid}/positions`);

      await deleteDoc(doc(positionsRef, id));

      const positionsSnapshot = await getDocs(positionsRef);

      const updatedPositions = positionsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      setPositions(updatedPositions);
    } catch (error) {
      console.error('Error removing position:', error.message);
    }
  };

  const editPosition = async (updatedPosition) => {
    try {
      const userUid = sessionStorage.getItem('authToken');
      const positionsRef = collection(db, `users/${userUid}/positions`);

      await updateDoc(doc(positionsRef, updatedPosition.id), updatedPosition);

      const positionsSnapshot = await getDocs(positionsRef);
      const updatedPositions = positionsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      setPositions(updatedPositions);
    } catch (error) {
      console.error('Error updating position:', error.message);
    }
  };

  const netPortfolioPosition = positions.reduce((sum, position) => sum + parseFloat(position.amount), 0);

  const textColor = netPortfolioPosition >= 0 ? '#00FF00' : '#FF0000';

  const groupPositionsByExchange = () => {
    const exchangeGroups = {};

    positions.forEach((position) => {
      const exchange = position.exchange || 'Other';
      if (!exchangeGroups[exchange]) {
        exchangeGroups[exchange] = [];
      }
      exchangeGroups[exchange].push(position);
    });

    return exchangeGroups;
  };

  const groupPositionsByAssetClass = () => {
    const assetClassGroups = {};

    positions.forEach((position) => {
      const assetClass = position.assetClass || 'Other';
      if (!assetClassGroups[assetClass]) {
        assetClassGroups[assetClass] = [];
      }
      assetClassGroups[assetClass].push(position);
    });

    return assetClassGroups;
  };

  const getNetPositionForExchange = (exchange) => {
    const exchangePositions = exchangeGroups[exchange] || [];
    const netPosition = exchangePositions.reduce((sum, position) => sum + parseFloat(position.amount), 0);
    const textColor = netPosition >= 0 ? '#00FF00' : '#FF0000';
    return (
      <span style={{ fontSize: '18px', color: textColor }}>
        Net: ${formatNumber(netPosition)}
      </span>
    );
  };

  const getNetPositionForAssetClass = (assetClass) => {
    const assetClassPositions = assetClassGroups[assetClass] || [];
    const netPosition = assetClassPositions.reduce((sum, position) => sum + parseFloat(position.amount), 0);
    const textColor = netPosition >= 0 ? '#00FF00' : '#FF0000';
    return (
      <span style={{ fontSize: '18px', color: textColor }}>
        Net: ${formatNumber(netPosition)}
      </span>
    );
  };

  const toggleExchangeVisibility = (exchange) => {
    setExpandedExchanges((prevExpandedExchanges) => {
      if (prevExpandedExchanges.includes(exchange)) {
        return prevExpandedExchanges.filter((e) => e !== exchange);
      } else {
        return [...prevExpandedExchanges, exchange];
      }
    });
  };

  const toggleExchangeVisibilityOnClick = (event, exchange) => {
    const clickedElement = event.target;
    const isHeaderClicked = clickedElement.classList.contains('exchange-column-header');
    if (isHeaderClicked) {
      toggleExchangeVisibility(exchange);
    }
  };

  const toggleSortByExchange = () => {
    setSortByExchange((prevState) => !prevState);
  };

  const exchangeGroups = sortByExchange ? groupPositionsByExchange() : {};
  const assetClassGroups = sortByExchange ? {} : groupPositionsByAssetClass();

  return (
    <div className="container">

      <h1>ΣTrader</h1>

      <span>Net Portfolio Position: </span>
      <span style={{ fontSize: '32px', color: textColor }}> ${formatNumber(netPortfolioPosition)} </span>

      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <span style={{ marginRight: '10px', fontSize: '20px', color: 'gainsboro', marginLeft: '30px', fontWeight: 'bold' }}>Portfolio</span>
      <button className="sort-button" onClick={toggleSortByExchange}>
        {sortByExchange ? 'Sort by Asset Class' : 'Sort by Exchange'}
      </button>
    </div>
      
      {sortByExchange ? (
        <div id="portfolio-section">
          {Object.keys(exchangeGroups).map((exchange) => (
            <div key={exchange} className="exchange-column" onClick={(event) => toggleExchangeVisibilityOnClick(event, exchange)}>
              <div className="exchange-column-header">
                <span onClick={() => toggleExchangeVisibility(exchange)}>{exchange}</span>
                {getNetPositionForExchange(exchange)}
              </div>
              {expandedExchanges.includes(exchange) && (
                <Portfolio
                  positions={exchangeGroups[exchange]}
                  onRemovePosition={removePosition}
                  onEditPosition={editPosition}
                />
              )}
            </div>
          ))}
        </div>
      ) : (
        <div id="portfolio-section" className="asset-class-columns">
          {Object.keys(assetClassGroups).map((assetClass) => (
            <div key={assetClass} className="asset-class-column">
              <div className="asset-class-column-header">
                <span style={{fontWeight: 'bold'}}>{assetClass}</span>
                {getNetPositionForAssetClass(assetClass)}
              </div>
              <Portfolio
                positions={assetClassGroups[assetClass]}
                onRemovePosition={removePosition}
                onEditPosition={editPosition}
              />
            </div>
          ))}
        </div>
      )}
      
      <span style={{ marginLeft: '30px', fontSize: '20px', color: 'gainsboro', fontWeight: 'bold', alignSelf: 'flex-start', marginTop: '30px' }}>Add Position</span>
      <div className="position-form-container">
        <PositionForm onSubmit={addPosition} />
      </div>
    </div>
  );
};

export default Sitrader;