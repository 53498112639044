import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { auth } from './firebase';
import { signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import '../Navbar.css';
import '../MaterialIcons.css';
import logoImage from '../assets/images/sitrader_logo_1.jpg';

const Navbar = ({ user, handleComponentChange, activeComponent }) => {
  const navigate = useNavigate();
  const [showDropdown, setShowDropdown] = useState(false);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      sessionStorage.removeItem('authToken');
      navigate('/login');
    } catch (error) {
      console.error('Logout error:', error.message);
    }
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  return (
    <nav className="navbar">
      <div className="navbar-left">
        <Link to="/" className="navbar-logo">
          <img src={logoImage} alt="App Logo" />
        </Link>
      </div>
      <div className="navbar-center">
        <button
          className={`navbar-button ${activeComponent === 'SiTrader' ? 'active' : ''}`}
          onClick={() => handleComponentChange('SiTrader')}
        >
          ΣTrader
        </button>
        <button
          className={`navbar-button ${activeComponent === 'SiFlow' ? 'active' : ''}`}
          onClick={() => handleComponentChange('SiFlow')}
        >
          ΣFlow
        </button>
        <button
          className={`navbar-button ${activeComponent === 'SiCalc' ? 'active' : ''}`}
          onClick={() => handleComponentChange('SiCalc')}
        >
          ΣCalc
        </button>
      </div>
      <div className="navbar-right">
        <div className="user-info">
          <span className="user-email">{user.email}</span>
          <span className="material-symbols-outlined user-picture" onClick={toggleDropdown}>
            account_circle
          </span>
        </div>
        <div className={`dropdown-menu ${showDropdown ? 'show' : ''}`}>
          <button>Settings</button>
          <button onClick={handleLogout}>Logout</button>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;