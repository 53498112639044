import React, { useState, useEffect } from 'react';
import '../Ticker.css'; // Import the Ticker.css file

const Ticker = ({ messages, speed = 50 }) => {
  const [contentWidth, setContentWidth] = useState(0);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const content = document.querySelector('.ticker-content');
      setContentWidth(content.offsetWidth);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div
      className="ticker-container"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div
        className={`ticker-content ${isHovered ? 'paused' : ''}`}
        style={{
          animation: `ticker-scroll ${contentWidth / speed}s linear infinite`,
          animationPlayState: isHovered ? 'paused' : 'running',
        }}
      >
        {messages.map((message, index) => (
          <span key={index} className="ticker-item">
            {message}
          </span>
        ))}
      </div>
    </div>
  );
};

export default Ticker;