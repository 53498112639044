import React from 'react';

const SiCalc = () => {
  return (
    <div className="container">
      <h1>ΣCalc</h1>
      <p>Coming Soon...</p>
      {/* Add your SiCalc component content here */}
    </div>
  );
};

export default SiCalc;