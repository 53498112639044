// src/firebase.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBU_ZeFERURCrIoXU8sE8ahR5ncgr8zgAI",
  authDomain: "sisuite-da21f.firebaseapp.com",
  projectId: "sisuite-da21f",
  storageBucket: "sisuite-da21f.appspot.com",
  messagingSenderId: "818299298760",
  appId: "1:818299298760:web:1afe361ba7c940107556fd",
  measurementId: "G-ZRN5M77LT2"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { auth, db, app }; // Add 'app' to the exports
