// Portfolio.js
import React, { useState } from 'react';
import Position from './Position';
import PositionForm from './PositionForm';
import '../styles.css';

const Portfolio = ({ positions, onRemovePosition, onEditPosition }) => {
  const [editingPosition, setEditingPosition] = useState(null);

  const handleEditClick = (position) => {
    setEditingPosition(position);
  };

  if (!positions || positions.length === 0) {
    return <p>No positions available.</p>;
  }

  // Group positions by asset class
  const groupedPositions = {};
  positions.forEach((position) => {
    if (!groupedPositions[position.assetClass]) {
      groupedPositions[position.assetClass] = [];
    }
    groupedPositions[position.assetClass].push(position);
  });

  return (
    <div id="portfolio-section">
      {Object.keys(groupedPositions).map((assetClass) => (
        <div key={assetClass}>
          {groupedPositions[assetClass].map((position, index) => (
            <div
              key={index}
              className={`position-container ${position.amount >= 0 ? 'long' : 'short'}`}
            >
              {editingPosition && editingPosition.id === position.id ? (
                <PositionForm
                  initialValues={editingPosition}
                  onSubmit={(updatedPosition) => {
                    onEditPosition(updatedPosition);
                    setEditingPosition(null);
                  }}
                  onCancel={() => setEditingPosition(null)}
                />
              ) : (
                <div className="position">
                  <Position
                    ticker={position.ticker}
                    amount={position.amount}
                    exchange={position.exchange}
                    assetClass={position.assetClass}
                  />
                  <button
                    type="button"
                    onClick={() => handleEditClick(position)}
                    className="edit-button"
                  >
                    Edit
                  </button>
                  <button
                    type="button"
                    onClick={() => onRemovePosition(position.id)}
                    className="remove-button"
                  >
                    x
                  </button>
                </div>
              )}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default Portfolio;