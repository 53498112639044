// PositionForm.js
import React, { useState } from 'react';
import '../styles.css';

const PositionForm = ({ initialValues, onSubmit, onCancel }) => {
  const [position, setPosition] = useState(initialValues || { ticker: '', amount: 0, exchange: 'MEXC', assetClass: 'Crypto' });

  const handleInputChange = (e, field) => {
    if (field === 'amount') {
      // Remove commas and convert to number
      const value = parseFloat(e.target.value.replace(/,/g, ''));
      setPosition({ ...position, [field]: isNaN(value) ? 0 : value });
    } else {
      setPosition({ ...position, [field]: e.target.value });
    }
  };

  const formatAmount = (amount) => {
    return amount.toLocaleString(undefined, { maximumFractionDigits: 2 });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (typeof onSubmit === 'function') {
      onSubmit(position);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="edit-form">
      <label>
        Ticker: <br />
        <input
          type="text"
          value={position.ticker}
          onChange={(e) => handleInputChange(e, 'ticker')}
        />
      </label>
      <label>
        Amount: <br />
        <input
          type="text"
          value={formatAmount(position.amount)}
          onChange={(e) => handleInputChange(e, 'amount')}
        />
      </label>
      <label>
        Asset Class: <br />
        <select
          value={position.assetClass}
          onChange={(e) => handleInputChange(e, 'assetClass')}
        >
          <option value="Crypto">Crypto</option>
          <option value="Commodities">Commodities</option>
          <option value="Indices">Indices</option>
          <option value="Forex">Forex</option>
        </select>
        <br />
        <input
          type="text"
          value={position.assetClass}
          onChange={(e) => handleInputChange(e, 'assetClass')}
          placeholder="Enter Asset Class"
        />
      </label>
      <label>
        Exchange: <br />
        <select
          value={position.exchange}
          onChange={(e) => handleInputChange(e, 'exchange')}
        >
          <option value="">Select Exchange</option>
          <option value="Oanda">Oanda</option>
          <option value="Phemex">Phemex</option>
          <option value="MEXC">MEXC</option>
          <option value="Binance">Binance</option>
          <option value="Deribit">Deribit</option>
        </select>
        <br />
        <input
          type="text"
          value={position.exchange}
          onChange={(e) => handleInputChange(e, 'exchange')}
          placeholder="Enter Exchange"
        />
      </label>

      <div className="position-form-button-group">
        <button className='edit-form-button-save' type="submit">Save</button>
        {onCancel && <button className='edit-form-button-cancel' type="button" onClick={onCancel}>Cancel</button>}
      </div>

    </form>
  );
};

export default PositionForm;