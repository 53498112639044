import React from 'react';
import logoImage from '../assets/images/sitrader_logo_1.jpg';
import { FaTwitter, FaInstagram, FaYoutube } from 'react-icons/fa'; // Import social media icons
import '../Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-logo-column">
          <img src={logoImage} alt="Your App Logo" className="footer-logo" />
          <div className="social-icons">
            <button onClick={() => window.open("https://twitter.com")}><FaTwitter /></button>
            <button onClick={() => window.open("https://instagram.com")}><FaInstagram /></button>
            <button onClick={() => window.open("https://youtube.com")}><FaYoutube /></button>
          </div>
        </div>
        <div className="footer-section">
          <h4>Explore</h4>
          <ul>
            <li><button>Features</button></li>
            <li><button>Pricing</button></li>
            <li><button>Documentation</button></li>
          </ul>
        </div>
        <div className="footer-section">
          <h4>Company</h4>
          <ul>
            <li><button>About Us</button></li>
            {/* <li><button>Careers</button></li> */}
            <li><button>Contact</button></li>
          </ul>
        </div>
        <div className="footer-section">
          <h4>Legal</h4>
          <ul>
            <li><button>Terms of Service</button></li>
            <li><button>Privacy Policy</button></li>
            <li><button>Cookie Policy</button></li>
          </ul>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; 2023 SiTerminal. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
