// src/components/LoginStyle.js
const loginStyles = {
  container: (isMobile) => ({
    width: isMobile ? "57%" : "37%",
    maxWidth: isMobile ? "70%" : "70%",
    margin: "auto",
    padding: "20px",
    border: "1px solid #ddd",
    borderRadius: "5px",
    marginTop: "50px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  }),
  header: {
    textAlign: "center",
    color: "#333",
    fontSize: "24px",
    marginBottom: "20px",
  },
  logo: {
    display: "block",
    margin: "auto",
    maxWidth: "60%",
    height: "auto",
    marginBottom: "30px",
  },
  input: {
    width: "80%",
    padding: "10px",
    margin: "5px 0 20px",
    boxSizing: "border-box",
    borderRadius: "3px",
    border: "1px solid #ddd",
    backgroundColor: "#f8f8f8",
  },
  button: {
    backgroundColor: "#1396d6",
    width: "80%",
    color: "white",
    padding: "15px 20px",
    border: "none",
    borderRadius: "3px",
    cursor: "pointer",
    fontSize: "16px",
    display: "block",
    margin: "auto",
    marginTop: "20px",
  },
  linkText: {
    marginTop: "15px",
    textAlign: "center",
    color: "#555",
    fontSize: "14px",
  },
  link: {
    color: "#3498db",
    textDecoration: "none",
  },
  notificationPopup: {
    position: "fixed",
    bottom: "20px",
    left: "50%",
    transform: "translateX(-50%)",
    backgroundColor: "#212121",
    color: "#fff",
    padding: "10px 15px",
    borderRadius: "5px",
    zIndex: "999",
    animation: "fadeIn 0.3s ease-in-out, fadeOut 0.3s ease-in-out 2.7s",
    display: "flex",
    alignItems: "center",
    maxWidth: "80%",
    boxShadow: "0 2px 10px rgba(0,0,0,0.2)",
    fontWeight: "bold",
  },
  notificationLogo: {
    width: "50px",
    height: "35px",
    marginRight: "10px",
    flexShrink: 0,
  },
  notificationText: {
    flex: 1,
    fontSize: "16px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  "@keyframes fadeIn": {
    "0%": {
      opacity: 0,
      transform: "translateX(-50%) translateY(20px)",
    },
    "100%": {
      opacity: 1,
      transform: "translateX(-50%) translateY(0)",
    },
  },
  "@keyframes fadeOut": {
    "0%": {
      opacity: 1,
      transform: "translateX(-50%) translateY(0)",
    },
    "100%": {
      opacity: 0,
      transform: "translateX(-50%) translateY(20px)",
    },
  },
};

export default loginStyles;