import React, { useState, useEffect } from "react";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { addDoc, collection } from "firebase/firestore";
import { auth, db } from "./firebase";
import { useNavigate, Link } from "react-router-dom";
import registerStyles from "./RegisterStyle";
import logoImage from "../assets/images/sitrader_logo_1.jpg";

const Register = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleRegister = async () => {
    if (password !== confirmPassword) {
      setErrorMessage("Passwords do not match. Please Try Again");
      return;
    }
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      await addDoc(collection(db, 'users'), {
        uid: userCredential.user.uid,
        email: email,
      });
      console.log("User registered:", userCredential.user.uid);
      sessionStorage.setItem("authToken", userCredential.user.uid);
      setShowSuccessPopup(true);
      setErrorMessage("");
      setTimeout(() => {
        setShowSuccessPopup(false);
        navigate("/dashboard");
      }, 3000);
    } catch (error) {
      console.error("Registration error:", error.message);
      setErrorMessage(parseErrorMessage(error.message));
    }
  };

  const parseErrorMessage = (errorMessage) => {
    const errorCode = errorMessage.slice(errorMessage.indexOf('/') + 1, errorMessage.indexOf(')'));
    switch (errorCode) {
      case 'email-already-in-use':
        return 'Email already in use.';
      case 'weak-password':
        return 'Password is too weak';
      default:
        return 'An error occurred. Please try again.';
    }
  };

  return (
    <div style={registerStyles.container(isMobile)}>
      <img src={logoImage} alt="Logo" style={registerStyles.logo} />
      <input
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        style={registerStyles.input}
        placeholder="Email"
      />
      <input
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        style={registerStyles.input}
        placeholder="Password"
      />
      <input
        type="password"
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
        style={registerStyles.input}
        placeholder="Confirm Password"
      />
      <button onClick={handleRegister} style={registerStyles.button}>
        Register
      </button>
      {errorMessage && (
        <p style={{ color: "red", marginTop: "10px" }}>{errorMessage}</p>
      )}
      <p style={registerStyles.linkText}>
        Already have an account? <Link to="/login" style={registerStyles.link}>Login Here</Link>
      </p>
      {showSuccessPopup && (
        <div style={registerStyles.notificationPopup}>
          <img
            src={logoImage}
            alt="Logo"
            style={registerStyles.notificationLogo}
          />
          <span style={registerStyles.notificationText}>
            Registration Successful
          </span>
        </div>
      )}
    </div>
  );
};

export default Register;